.basicLightbox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: hsl(var(--bg-hsl) / 80%);
  backdrop-filter: blur(2px);
  opacity: 0.01;
  transition: opacity 0.4s ease;
  z-index: 1000;
  will-change: opacity;
}

.basicLightbox--visible {
  opacity: 1;
}

.basicLightbox__placeholder {
  max-width: 100%;
  transform: scale(0.9);
  transition: transform 0.4s ease;
  z-index: 1;
  will-change: transform;
}

.basicLightbox__placeholder > iframe:first-child:last-child,
.basicLightbox__placeholder > img:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 95%;
  max-height: 95%;
}

.basicLightbox__placeholder > iframe:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  pointer-events: auto;
}

.basicLightbox__placeholder > img:first-child:last-child,
.basicLightbox__placeholder > video:first-child:last-child {
  width: auto;
  height: auto;
}

.basicLightbox--iframe .basicLightbox__placeholder,
.basicLightbox--img .basicLightbox__placeholder,
.basicLightbox--video .basicLightbox__placeholder {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.basicLightbox--visible .basicLightbox__placeholder {
  transform: scale(1);
}
