.prose {
  --flow-space: 1em;

  > * + * {
    margin-top: var(--flow-space);
  }
}

.markdown {
  & > * + * {
    margin-top: 0.5em;
  }

  figure:not([class]) {
    width: 33.3%;
    float: right;

    & > img {
      width: 100%;
    }
  }

  p {
    max-width: 60ch;
  }

  & > h2 {
    max-width: 25ch;
  }
}

.cap {
  :first-child {
    text-indent: 5vw;
  }

  :first-child::first-letter {
    color: var(--accent-color);
    font-size: var(--step-4);
    font-variation-settings: "wght" 800;
    line-height: 0;
  }
}
