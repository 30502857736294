footer {
  .footer-inner {
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0.5rem 0;
    font-variation-settings: "opsz" 14, "wght" 300, "wdth" 87.5;
    font-size: var(--step--1);
    opacity: 0.8;
    border-top: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
    gap: 5vw;
  }

  div.dev {
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

/* Detailed thanks on last page only */
[data-template="a-propos"] div.dev {
  display: inline;
  text-align: end;
}
