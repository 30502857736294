@import url("./bricolage-grotesque.css");

  /* OLD SCALE
    https://utopia.fyi/type/calculator?c=360,18,1.2,1240,18,1.414,5,2,&s=0.75%7C0.5%7C0.25,1.5%7C2%7C3%7C4%7C6,s-l&g=s,l,2xl,10
  */

/* @link https://utopia.fyi/type/calculator?c=360,16,1.25,1440,24,1.5,4,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,2xl,10 */

:root {
  --step--2: clamp(0.64rem, 0.6311rem + 0.0395vw, 0.6667rem);
  --step--1: clamp(0.8rem, 0.7333rem + 0.2963vw, 1rem);
  --step-0: clamp(1rem, 0.8333rem + 0.7407vw, 1.5rem);
  --step-1: clamp(1.25rem, 0.9167rem + 1.4815vw, 2.25rem);
  --step-2: clamp(1.5625rem, 0.9583rem + 2.6852vw, 3.375rem);
  --step-3: clamp(1.9531rem, 0.9167rem + 4.6065vw, 5.0625rem);
  --step-4: clamp(2.4414rem, 0.724rem + 7.6331vw, 7.5938rem);
}

sup, sub {
  line-height: 0;
  text-transform: none;
  font-size: max(14px, 0.6em);
}

:is(h1, h2, h3):not(.link) {
  color: var(--bg-very-dark);

  /* color: var(--accent-color-dark); */

  text-wrap: balance;
  line-height: 1.1;
  letter-spacing: -0.02em;
}

.accent {
  color: var(--accent-color);
}

p {
  line-height: 1.5;
  margin: 0;
}

p + p {
  margin-top: 1em;
}

strong {
  font-variation-settings: "optz" 30, "wght" 600, "wdth" 100;
}

h1 {
  font-size: var(--step-3);
  font-variation-settings: "optz" 50, "wght" 800, "wdth" 100;
}

.h2,
h2 {
  font-size: var(--step-2);
  font-variation-settings: "optz" 35, "wght" 500, "wdth" 100;
}

.title > h1,
.title > h2 {
  line-height: 1;
  color: var(--text-color-white);
}

.h3,
h3 {
  font-size: var(--step-1);
  font-variation-settings: "optz" 35, "wght" 400, "wdth" 100;
}

a {
  outline-color: transparent;
  position: relative;
}

a.card,
a.branding {
  text-decoration: none;
  color: inherit;
}

a:not(:is(.button, .card)) {
  text-underline-offset: 0.15em;
  color: var(--text-color);
  text-decoration-thickness: max(1px, 0.08em);

  &:link {
    text-decoration-color: var(--accent-color);
  }

  &:visited {
    color: var(--text-color);
    text-decoration-color: var(--accent-color);
  }

  &:focus {
    text-decoration: none;
    color: var(--accent-color);
  }

  &:hover {
    color: var(--accent-color);
    text-decoration-color: var(--text-color-secondary);
  }

  &:active {
    color: var(--accent-color);
  }
}

/* https://css-irl.info/styling-external-links-with-attribute-selectors/ */

/* a[href^='http']::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 12.2 12.2' width='14' height='14'%3E%3Cpath d='M5.7 0v1.5h4L4.5 6.7l1 1.1 5.3-5.2v3.9h1.4V0z'/%3E%3Cpath fill='none' d='M3.4 6.7l3-2.9H1.5v7h7V5.9l-3 2.9z'/%3E%3Cpath d='M8.5 5.9v4.9h-7v-7h4.9l1.5-1.6H0v10h10V4.4z'/%3E%3C/svg%3E");
  height: 1em;
  margin-left: 0.25em;
} */

button,
.button {
  border: 1px solid var(--accent-color);
  border-radius: var(--button-radius);
  background: hsl(var(--bg-hsl) / 60%);
  color: var(--accent-color);
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
  transition: 0.2s ease all;

  &:hover {
    background: var(--accent-color-light);
    color: var(--bg);
  }
}

.bold {
  font-variation-settings: "wght" 600;
}
