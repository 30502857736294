/*
CLUSTER
More info: https://every-layout.dev/layouts/cluster/
A layout that lets you distribute items with consitent
spacing, regardless of their size

CUSTOM PROPERTIES AND CONFIGURATION
--gutter (var(--space-s-m)): This defines the space
between each item.

--cluster-horizontal-alignment (flex-start) How items should align
horizontally. Can be any acceptable flexbox aligmnent value.

--cluster-vertical-alignment How items should align vertically.
Can be any acceptable flexbox alignment value.
*/

.cluster {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter, var(--space-s-m));
  justify-content: var(--cluster-horizontal-alignment, flex-start);
  align-items: var(--cluster-vertical-alignment, center);

  &[data-spacing = "space-between"] {
    --cluster-horizontal-alignment: space-between;
  }
}
