/* colors */

:root {
  --accent-hue: 34deg;
  --accent-sat: 87%;
  --accent-lit: 39%;
  --accent-color-fallback: hsl(34deg 87% 39%);

  /* colors */
  --bg-hsl: 210 17% 98%; /* hsl(210 17% 100%) */
  --bg: hsl(var(--bg-hsl));
  --bg-dark: hsl(210 17% 94%);
  --text-color-hsl: 210 10% 23%; /* hsl(210 10% 23%) */
  --text-color: hsl(var(--text-color-hsl));
  --text-color-secondary-hsl: 210 9% 31%; /* hsl(210 9% 31%) */
  --text-color-secondary: hsl(var(--text-color-secondary-hsl));
  --text-color-white: #fefefe;

  /* for svg */
  --stroke-color: var(--text-color);
  --fill-color: var(--bg);
}
