header {
  /* @link https://utopia.fyi/clamp/calculator?a=798,1440,20—28 */
  --fluid-20-28: clamp(20px, 10.0561px + 1.2461vi, 28px);
  --gutter: 0.5rem clamp(1em, 5vw, 5em);

  font-size: var(--fluid-20-28);
  font-variation-settings:
    "optz" 50,
    "wght" 400,
    "wdth" 87.5;
}

.branding {
  --icon-height: 1em;

  svg {
    transform: translateY(0.2rem);
  }

  font-variation-settings:
    "optz" 50,
    "wght" 800,
    "wdth" 82.5;
  color: var(--text-color-white) !important;
  white-space: nowrap;
}

nav,
.navbar,
#nav-links {
  display: flex;
  gap: var(--gutter);
  flex-wrap: wrap;
  flex: 1 1 5vw;
  justify-content: space-between;

  a {
    color: var(--text-color-white);

    /* stylelint-disable-next-line no-descending-specificity */
    &:hover {
      color: var(--text-color-white);
      text-decoration-color: var(--text-color-white);
    }
  }

  .button {
    background: inherit;
  }

  .icon svg {
    position: absolute;
    top: 0.2em;
    right: -0.8em;
    width: 1em;
    height: 1em;
  }
}

nav {
  padding: 0.75em;
}

.navbar {
  flex: 1 1 auto;
  padding-left: 0.75em;
}

li.submenu {
  position: relative;

  ul {
    position: absolute;
    top: 2em;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: -1;
    opacity: 0;
    transform: translateY(-20%);
    transition: all 0.3s ease;
    font-size: var(--step-0);
  }

  &:hover {
    ul {
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }
  }

  @media screen and (max-width: 600px) {
    .icon,
    ul {
      display: none;
    }
  }
}

.cta {
  flex: 0 1 auto;
  margin-inline-start: auto;
  font-size: var(--fluid-20-28) !important;
  background: transparent;
  font-variation-settings:
    "optz" 50,
    "wght" 500,
    "wdth" 87.5;

  &:hover {
    background-color: var(--accent-color) !important;
  }
}

a[aria-current="page"] {
  text-decoration: none;

  /* text-decoration-color: var(--text-color-secondary); */

  &::before {
    content: "●";
    color: var(--accent-color);
    position: absolute;
    top: -0.2em;
    left: -0.75em;
  }

  &:hover {
    color: var(--text-color);
  }
}
