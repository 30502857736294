/*
Try to implement CUBE methodology
See feks https://cube.fyi/ and https://every-layout.dev
*/
@import url("./global/index.css");
@import url("./compositions/index.css");
@import url("./utilities/index.css");
@import url("./blocks/prose.css");
@import url("./blocks/page_header.css");
@import url("./blocks/page_footer.css");
@import url("./layout/components.css");
@import url("./vendor/basiclightbox.css");
@import url("./shame.css");

/* Some variables */
:root {
  --box-radius: 0.5rem;
  --box-border: 1px solid;
  --button-radius: 0.25rem;
  --wrapper-max-width: 80vw;
  --gutter: 1rem;
}
