/*
VISUALLY HIDDEN UTILITY
Info: https://piccalil.li/quick-tip/visually-hidden/
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
