/**
 * CSS for Bricolage Grotesque
 * Generated by Wakamai Fondue - https://wakamaifondue.com
 * by Roel Nieskens/PixelAmbacht - https://pixelambacht.nl
 */

@font-face {
    font-family: "Bricolage Grotesque";
    src: url("../fonts/BricolageGrotesque[opsz,wdth,wght].woff2") format("woff2-variations");
    font-weight: 200 800;
    font-stretch: 75% 100%;
    font-display: swap;
    unicode-range: U+0020-007E, U+00A0-00A3, U+00A5-00AC, U+00AE-0107,
        U+010A-0113, U+0116-011B, U+011E-0123, U+0126-012B, U+012E-0133,
        U+0136-0137, U+0139-013E, U+0141-0148, U+014A-014D, U+0150-015B,
        U+015E-0161, U+0164-0165, U+0168-017E, U+01A0-01A1, U+01AF-01B0,
        U+01CD-01CE, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
        U+0300-0304, U+0306-030C, U+0312, U+031B, U+0323, U+0326-0328, U+03C0,
        U+0E3F, U+1E80-1E85, U+1E9E, U+1EA0-1EF9, U+2000-200B, U+2013-2014,
        U+2016, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030,
        U+2032-2033, U+2039-203A, U+2044, U+2070, U+2074-2079, U+2080-2089,
        U+20A1, U+20A4, U+20A6, U+20A8-20A9, U+20AB-20AE, U+20B1-20B2,
        U+20B4-20B5, U+20B8-20BA, U+20BC-20BF, U+2113, U+2116, U+2122, U+2126,
        U+212E, U+2153-2154, U+215B-215E, U+2190-2193, U+2202, U+2205-2206,
        U+220F, U+2211-2212, U+221A, U+221E, U+222B, U+2248, U+2260,
        U+2264-2265, U+27E8-27E9;
}

/* Set custom properties for each layout feature */
:root {
    --bricolage-grotesque-aalt: "aalt" off;
    --bricolage-grotesque-case: "case" off;
    --bricolage-grotesque-dnom: "dnom" off;
    --bricolage-grotesque-frac: "frac" off;
    --bricolage-grotesque-lnum: "lnum" off;
    --bricolage-grotesque-numr: "numr" off;
    --bricolage-grotesque-onum: "onum" off;
    --bricolage-grotesque-ordn: "ordn" off;
    --bricolage-grotesque-pnum: "pnum" off;
    --bricolage-grotesque-sinf: "sinf" off;
    --bricolage-grotesque-subs: "subs" off;
    --bricolage-grotesque-sups: "sups" off;
    --bricolage-grotesque-tnum: "tnum" off;
}

/* If class is applied, update custom property and
   apply modern font-variant-* when supported */
.bricolage-grotesque-aalt {
    --bricolage-grotesque-aalt: "aalt" on;
}

.bricolage-grotesque-case {
    --bricolage-grotesque-case: "case" on;
}

.bricolage-grotesque-dnom {
    --bricolage-grotesque-dnom: "dnom" on;
}

.bricolage-grotesque-frac {
    --bricolage-grotesque-frac: "frac" on;
}

@supports (font-variant-numeric: diagonal-fractions) {
    .bricolage-grotesque-frac {
        --bricolage-grotesque-frac: "____";

        font-variant-numeric: diagonal-fractions;
    }
}

.bricolage-grotesque-lnum {
    --bricolage-grotesque-lnum: "lnum" on;
}

@supports (font-variant-numeric: lining-nums) {
    .bricolage-grotesque-lnum {
        --bricolage-grotesque-lnum: "____";

        font-variant-numeric: lining-nums;
    }
}

.bricolage-grotesque-numr {
    --bricolage-grotesque-numr: "numr" on;
}

.bricolage-grotesque-onum {
    --bricolage-grotesque-onum: "onum" on;
}

@supports (font-variant-numeric: oldstyle-nums) {
    .bricolage-grotesque-onum {
        --bricolage-grotesque-onum: "____";

        font-variant-numeric: oldstyle-nums;
    }
}

.bricolage-grotesque-ordn {
    --bricolage-grotesque-ordn: "ordn" on;
}

@supports (font-variant-numeric: ordinal) {
    .bricolage-grotesque-ordn {
        --bricolage-grotesque-ordn: "____";

        font-variant-numeric: ordinal;
    }
}

.bricolage-grotesque-pnum {
    --bricolage-grotesque-pnum: "pnum" on;
}

@supports (font-variant-numeric: proportional-nums) {
    .bricolage-grotesque-pnum {
        --bricolage-grotesque-pnum: "____";

        font-variant-numeric: proportional-nums;
    }
}

.bricolage-grotesque-sinf {
    --bricolage-grotesque-sinf: "sinf" on;
}

.bricolage-grotesque-subs {
    --bricolage-grotesque-subs: "subs" on;
}

@supports (font-variant-position: sub) {
    .bricolage-grotesque-subs {
        --bricolage-grotesque-subs: "____";

        font-variant-position: sub;
    }
}

.bricolage-grotesque-sups {
    --bricolage-grotesque-sups: "sups" on;
}

@supports (font-variant-position: super) {
    .bricolage-grotesque-sups {
        --bricolage-grotesque-sups: "____";

        font-variant-position: super;
    }
}

.bricolage-grotesque-tnum {
    --bricolage-grotesque-tnum: "tnum" on;
}

@supports (font-variant-numeric: tabular-nums) {
    .bricolage-grotesque-tnum {
        --bricolage-grotesque-tnum: "____";

        font-variant-numeric: tabular-nums;
    }
}

/* Apply current state of all custom properties
   whenever a class is being applied */
.bricolage-grotesque-aalt,
.bricolage-grotesque-case,
.bricolage-grotesque-dnom,
.bricolage-grotesque-frac,
.bricolage-grotesque-lnum,
.bricolage-grotesque-numr,
.bricolage-grotesque-onum,
.bricolage-grotesque-ordn,
.bricolage-grotesque-pnum,
.bricolage-grotesque-sinf,
.bricolage-grotesque-subs,
.bricolage-grotesque-sups,
.bricolage-grotesque-tnum {
    font-feature-settings: var(--bricolage-grotesque-aalt), var(--bricolage-grotesque-case),
        var(--bricolage-grotesque-dnom), var(--bricolage-grotesque-frac), var(--bricolage-grotesque-lnum),
        var(--bricolage-grotesque-numr), var(--bricolage-grotesque-onum), var(--bricolage-grotesque-ordn),
        var(--bricolage-grotesque-pnum), var(--bricolage-grotesque-sinf), var(--bricolage-grotesque-subs),
        var(--bricolage-grotesque-sups), var(--bricolage-grotesque-tnum);
}

/* Variable instances */
.bricolage-grotesque-extralight {
    font-variation-settings: "opsz" 14, "wght" 200, "wdth" 100;
}

.bricolage-grotesque-light {
    font-variation-settings: "opsz" 14, "wght" 300, "wdth" 100;
}

.bricolage-grotesque-regular {
    font-variation-settings: "opsz" 14, "wght" 400, "wdth" 100;
}

.bricolage-grotesque-medium {
    font-variation-settings: "opsz" 14, "wght" 500, "wdth" 100;
}

.bricolage-grotesque-semibold {
    font-variation-settings: "opsz" 14, "wght" 600, "wdth" 100;
}

.bricolage-grotesque-bold {
    font-variation-settings: "opsz" 14, "wght" 700, "wdth" 100;
}

.bricolage-grotesque-extrabold {
    font-variation-settings: "opsz" 14, "wght" 800, "wdth" 100;
}

