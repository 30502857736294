@import url("./modern-reset.css");
@import url("./modern-normalize.css");
@import url("./variables.css");
@import url("./typography.css");

body {
  --header-height: 80px;

  position: relative;
  background:
    linear-gradient(
        hsl(var(--bg-very-dark-hsl) / 90%) 0,
        hsl(var(--bg-hsl) / 100%) 75vh
      )
      no-repeat,
    linear-gradient(var(--bg), var(--bg));
  color: var(--text-color);
  font-family:
    "Bricolage Grotesque",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    sans-serif;
  font-variation-settings:
    "opsz" 14,
    "wght" 300,
    "wdth" 100;
  font-size: var(--step-0);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Scrolling gradient: https://codepen.io/MadeByMike/pen/eKPZZz */

/* body::before {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  background: linear-gradient(
    hsl(var(--bg-very-dark-hsl) / 70%) 10%,
    hsl(var(--bg-very-dark-hsl) / 50%) 20%,
    hsl(var(--bg-hsl) / 40%) 75%
  );
} */
