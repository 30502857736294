ul.cards {
  list-style: none;
}

.box {
  background: var(--bg-dark);
  border-radius: var(--box-radius);
  height: 100%;
  width: 50vw;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: start;
}
